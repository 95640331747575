<template>
  <v-container
    grid-list-xl
  >
    <v-form>
      <v-layout v-if="hasAction('view_settings') || isAdmin()">
        <v-flex
          xs12
          sm8
          md6
        >
          <v-text-field
            v-model="discount"
            :label="$t('generalConfiguration.discount')"
            :disabled="!(hasAction('update_settings') || isAdmin()) || isEntityDisabled()"
          />
        </v-flex>
      </v-layout>
      <v-btn
        v-if="(hasAction('update_settings') && !isEntityDisabled()) || isAdmin()"
        style="margin-right: 0;"
        class="pull-right"
        color="success"
        @click="onCreateOrUpdateDiscountConfig($event)"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-ok" />
        </span>
        <span style="text-transform: none;">{{
          $t("common.save")
        }}</span>
      </v-btn>
    </v-form>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    discount: 0,
    entityType: null,
    entityId: null,
    objectSettingKey: stringUtils.ObjectSettingKey
  }),
  computed: {
    ...mapGetters([
      'OBJECT_SETTING_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      let discountSettingData = data.filter(element => {
        return element.key === this.objectSettingKey.DISCOUNT
      })
      if (discountSettingData.length > 0) {
        this.discount = discountSettingData[0].value
      }
    }
  },
  created () {
    this.entityId = sessionStorage.getItem('entityId')
    this.entityType = sessionStorage.getItem('entityType')
    let filter = {
      params: {
        objectId: this.entityId,
        objectType: 'Entity'
      }
    }
    this.GET_OBJECT_SETTING(filter)
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Create or update discount config
     */
    onCreateOrUpdateDiscountConfig: function (event) {
      event.preventDefault()
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        objectId: this.entityId,
        objectType: 'Entity',
        key: this.objectSettingKey.DISCOUNT,
        value: this.discount
      }
      this.CREATE_OBJECT_SETTING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
