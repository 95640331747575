<template>
  <v-layout
    v-if="isAdmin()"
    wrap>
    <v-flex md12>
      <material-card
        :title="$t('generalConfiguration.configurationDefaultEntityForStore')"
        color="green"
        flat
        full-width
      >
        <div>
          <v-container grid-list-xl>
            <v-form>
              <v-layout
                wrap
                layout
              >
                <v-flex
                  xs12
                  sm12
                  md12
                >
                  <v-select
                    v-model="storeChannelId"
                    :items="storeChannelsList"
                    :label="$t('storeChannel.title')"
                    item-text="text"
                    item-value="value"
                    @change="onChangeStoreChannels(storeChannelId)"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md12
                >
                  <v-autocomplete
                    :items="agents"
                    v-model="agentId"
                    :label="$t('entity.agent')"
                    :clearable="true"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-form>
            <v-spacer/>
            <v-btn
              v-if="isAdmin()"
              color="success darken-1"
              small
              float-right
              @click="onConfirm"
            >{{ $t('common.save') }}</v-btn>
          </v-container>
        </div>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
// import ToastType from 'enum/toastType'
// import ServiceType from 'enum/serviceType'
import EntityType from 'enum/entityType'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
export default {
  components: {
  },
  data: () => ({
    valid: false,
    entity: {},
    allProjects: [],
    objectSettingKey: stringUtils.ObjectSettingKey,
    agents: [],
    agentId: null,
    storeChannelsList: [],
    storeChannelId: null,
    storeChannelsKey: stringUtils.storeChannelsKey
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR',
      'LIST_ENTITIES_DATA'
    ])
  },
  watch: {
  },
  async created () {
    await this.getStoreChannels()
    // await this.getSettingConfig()
    await this.getListEntitiesByType()
  },
  methods: {
    /**
     * Is entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    /**
     * Has system role
     */
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save SMTP Project
     */
    onConfirm: function () {
      // let data = {}
      if (functionUtils.isEmptyString(this.agentId)) {
        this.ON_SHOW_TOAST({
          'message': this.$t('entity.agent'),
          'styleType': ToastType.ERROR
        })
        return
      }
      this.createSettingConfig(this.getStoreChannelsKey(), this.agentId)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    getListEntitiesByType: function () {
      let filter = {
        params: {
          type: EntityType.AGENT
        }
      }
      this.isLoading = true
      this.GET_LIST_ENTITIES_BY_TYPE(filter)
        .then(
          function (res) {
            let data = res.data
            this.agents = []
            data.forEach(e => {
              let item = {
                value: e.id,
                text: e.name
              }
              this.agents.push(item)
            })
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Get Bao Kim public key
     */
    getSettingConfig: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.getStoreChannelsKey()
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.agentId = parseInt(data[0].value)
          } else {
            this.agentId = null
          }
        }.bind(this)
      )
    },
    /**
     * create setting config
     */
    createSettingConfig: function (key, value) {
      let filter = {
        objectId: null,
        objectType: 'System',
        key: key,
        value: value
      }
      this.CREATE_OBJECT_SETTING(filter)
    },
    /**
     * Get domain
     */
    getStoreChannels: function () {
      let filter = {
        params: {
          allowChangeEntity: true
        }
      }
      this.isLoading = true
      this.GET_STORE_CHANNELS_LIST(filter).then(
        function (res) {
          this.isLoading = false
          let data = res.data
          this.storeChannelsList = []
          for (let i = 0, size = data.length; i < size; i++) {
            let storeChannelObj = {
              value: data[i].id,
              text: data[i].name,
              type: data[i].type
            }
            this.storeChannelsList.push(storeChannelObj)
          }
          if (this.storeChannelsList.length > 0) {
            this.storeChannelId = this.storeChannelsList[0].value
            this.getSettingConfig()
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onChangeStoreChannels: function (value) {
      this.getSettingConfig()
    },
    getStoreChannelsKey: function () {
      let resultData = null
      if (this.storeChannelsList.length > 0) {
        let index = this.storeChannelsList.findIndex(x => x.value === this.storeChannelId)
        if (index !== -1) {
          let storeChannelId = this.storeChannelsList[index].value
          resultData = this.storeChannelsKey + `${storeChannelId}`
        }
      }
      return resultData
    },
    /**
     * Get channel name
     */
    getChannelName: function (type) {
      return functionUtils.getDefaultStoreChannelName(type)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_LIST_ENTITIES_BY_TYPE',
      'GET_OBJECT_SETTING',
      'CREATE_OBJECT_SETTING',
      'GET_STORE_CHANNELS_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
