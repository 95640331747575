<template>
  <v-container
    grid-list-xl
  >
    <v-form>
      <v-layout
        v-if="hasAction('view_settings') || isAdmin()"
        layout
        wrap>
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 1"
            :key="i"
          >
            <template v-slot:header>
              <div v-if="item == 1">
                {{ $t('generalConfiguration.companyGeneral') }}
              </div>
            </template>
            <template>
              <div v-if="item == 1">
                <v-container
                  grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-text-field
                          v-model="company_name"
                          :label="$t('generalConfiguration.companyName')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-textarea
                          v-model="company_addr"
                          :label="$t('generalConfiguration.companyAddress')"
                          rows="3"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="company_email"
                          :label="$t('generalConfiguration.companyEmail')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="company_hotline"
                          :label="$t('generalConfiguration.companyHotline')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-text-field
                          v-model="company_facebook"
                          :label="$t('generalConfiguration.companyFacebook')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    v-if="hasAction('update_settings') || isAdmin()"
                    style="margin-right: 0;"
                    class="pull-right"
                    color="success"
                    @click="onCreateOrUpdateCompanyInfoConfig($event)"
                  >
                    <span class="btn-label mr-3">
                      <i class="glyphicon glyphicon-ok" />
                    </span>
                    <span style="text-transform: none;">{{
                      $t("common.save")
                    }}</span>
                  </v-btn>
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-layout>
    </v-form>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    company_name: null,
    company_addr: null,
    company_email: null,
    company_hotline: null,
    company_facebook: null,
    entityType: null,
    entityId: null,
    objectSettingKey: stringUtils.ObjectSettingKey
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
  },
  created () {
    this.getCompanyName()
    this.getCompanyAddr()
    this.getCompanyEmail()
    this.getCompanyHotline()
    this.getCompanyFacebook()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Get company setting info
     */
    getCompanyName: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.COMPANY_NAME
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.company_name = data[0].value
          }
        }.bind(this)
      )
    },
    getCompanyAddr: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.COMPANY_ADDRESSS
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.company_addr = data[0].value
          }
        }.bind(this)
      )
    },
    getCompanyEmail: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.COMPANY_EMAIL
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.company_email = data[0].value
          }
        }.bind(this)
      )
    },
    getCompanyHotline: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.COMPANY_HOTLINE
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.company_hotline = data[0].value
          }
        }.bind(this)
      )
    },
    getCompanyFacebook: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.COMPANY_FACEBOOK
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.company_facebook = data[0].value
          }
        }.bind(this)
      )
    },
    /**
     * Create or update company setting info
     */
    createOrUpdateCompanyConfig: function (_key, _value) {
      let filter = {
        objectId: null,
        objectType: 'System',
        key: _key,
        value: _value
      }
      this.CREATE_OBJECT_SETTING(filter)
    },
    onCreateOrUpdateCompanyInfoConfig: async function (event) {
      event.preventDefault()
      await this.createOrUpdateCompanyConfig(this.objectSettingKey.COMPANY_NAME, this.company_name)
      await this.createOrUpdateCompanyConfig(this.objectSettingKey.COMPANY_ADDRESSS, this.company_addr)
      await this.createOrUpdateCompanyConfig(this.objectSettingKey.COMPANY_EMAIL, this.company_email)
      await this.createOrUpdateCompanyConfig(this.objectSettingKey.COMPANY_HOTLINE, this.company_hotline)
      await this.createOrUpdateCompanyConfig(this.objectSettingKey.COMPANY_FACEBOOK, this.company_facebook)
      this.ON_SHOW_TOAST({
        'message': this.$t('common.success'),
        'styleType': ToastType.SUCCESS
      })
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
