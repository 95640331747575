<template>
  <v-container
    grid-list-xl>
    <v-form>
      <v-layout
        wrap
        layout
      >
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 1"
            :key="i"
          >
            <template v-slot:header>
              <div v-if="item == 1">
                {{ $t('smsConfig.info') }}
              </div>
            </template>
            <template>
              <div v-if="item == 1">
                <v-container
                  grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <!-- SMS provider -->
                        <v-select
                          v-model="smsServer.providerType"
                          :items="ServiceSMSProviderList"
                          :label="$t('generalConfiguration.smsProvider')"
                          :disabled="!isAdmin() || isEntityDisabled()"
                        >
                          <template
                            slot="selection"
                            slot-scope="data">
                            {{ data.item.text }}
                          </template>
                          <template
                            slot="item"
                            slot-scope="data">
                            {{ data.item.text }}
                          </template>
                        </v-select>
                      </v-flex>
                      <!-- Brandname -->
                      <v-flex
                        v-show="!isNull(smsServer.providerType)"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smsServer.brandname"
                          :label="$t('smsConfig.brandName')"
                          :disabled="isNull(smsServer.providerType) || isEntityDisabled()"
                          class="required"
                        />
                      </v-flex>
                      <!-- API Token -->
                      <v-flex
                        v-show="!isNull(smsServer.providerType)"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smsServer.tokenKey"
                          :label="$t('smsConfig.tokenKey')"
                          :disabled="isNull(smsServer.providerType) || isEntityDisabled()"
                          class="required"
                        />
                      </v-flex>
                      <!-- API Secret Key -->
                      <v-flex
                        v-show="!isNull(smsServer.providerType)"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smsServer.secretKey"
                          :label="$t('smsConfig.secretKey')"
                          :append-outer-icon="'mdi-dots-horizontal'"
                          :disabled="isNull(smsServer.providerType) || isEntityDisabled()"
                          type="password"
                          readonly
                          @click:append-outer="onShowModalChangePasswordSystemConfig"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    :disabled="!isAdmin() && (isNull(smsServer.providerType) || isEntityDisabled())"
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSMSConfigInfo"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-layout>
      <snack-bar
        v-for="snackbar in GET_SNACK_BAR"
        :key="snackbar.id"
        :item="snackbar"
      />
    </v-form>
    <changePasswordModal ref="changePasswordModal" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ToastType from 'enum/toastType'
import ChangePasswordModal from './ChangePasswordModal'
import ServiceType from 'enum/serviceType'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    ChangePasswordModal
  },
  data: () => ({
    smsServer: {
      providerType: null,
      tokenKey: null,
      secretKey: null,
      brandname: null
    },
    ServiceSMSProviderList: stringUtils.ServiceSMSProviderList,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  created () {
    this.getSmsConfigInfo()
  },
  methods: {
    isNull: function (value) {
      return functionUtils.isNull(value)
    },
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Show modal config password for system service
     */
    onShowModalChangePasswordSystemConfig: function () {
      this.$refs.changePasswordModal.onShowModal(ServiceType.SMS)
    },
    /**
     * Get sms config info
     */
    getSmsConfigInfo: function () {
      this.GET_SMS_CONFIG_INFO().then(
        function (res) {
          let data = res.data
          this.smsServer.providerType = data.provider_type
          this.smsServer.tokenKey = data.api_token
          this.smsServer.brandname = data.brandname
          this.smsServer.secretKey = data.api_secret_key ? '*****' : ''
        }.bind(this)
      )
    },
    /**
     * Save sms config info
     */
    saveSMSConfigInfo: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        apiToken: this.smsServer.tokenKey,
        // apiSecretKey: null,
        smsProviderType: this.smsServer.providerType,
        brandname: this.smsServer.brandname
      }
      this.SAVE_SMS_CONFIG_INFO(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.smsServer.secretKey = null
          this.getSmsConfigInfo()
        }.bind(this)
      )
    },
    ...mapActions([
      'SAVE_SMS_CONFIG_INFO',
      'GET_SMS_CONFIG_INFO',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
