<template>
  <v-layout
    wrap>
    <v-flex md12>
      <material-card
        :title="$t('sideBar.supplierConfiguration')"
        color="green"
        flat
        full-width
      >
        <div>
          <v-container grid-list-xl>
            <v-form
              ref="form"
              lazy-validation>
              <v-layout
                wrap
                layout
              >
                <v-flex
                  xs8
                  md8
                  sm12
                >
                  <v-text-field
                    :disabled="isEntityDisabled()"
                    :rules="[ ruleValidEmailAllowNull ]"
                    v-model="entity_cfg.deliveryNotificationEmail"
                    :label="$t('entity.deliveryNotificationEmail')"
                    :name="$t('entity.deliveryNotificationEmail')"
                  />
                </v-flex>
                <v-flex
                  xs8
                  md8
                  sm12
                >
                  <v-text-field
                    :disabled="isEntityDisabled()"
                    :rules="[ ruleValidEmailAllowNull ]"
                    v-model="entity_cfg.managerNotificationEmail"
                    :label="$t('entity.managerNotificationEmail')"
                    :name="$t('entity.managerNotificationEmail')"
                  />
                </v-flex>
              </v-layout>
            </v-form>
            <v-spacer/>
            <v-btn
              :disabled="isEntityDisabled()"
              color="success darken-1"
              small
              float-right
              @click="onConfirm"
            >{{ $t('common.save') }}</v-btn>
          </v-container>
        </div>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
export default {
  components: {
  },
  data: () => ({
    entity_cfg: {
      // numberOfUsersInEntity: null,
      // numberOfVoucherCreateEach: null,
      // numberOfVoucherPerMonth: null,
      // numberOfVoucherCreated: null,
      // numberOfSmsSent: null,
      // numberOfAgentCreated: null,
      // numberOfSiteCreated: null,
      hasManageWebPortal: false,
      hasGiveVoucherToHaravan: false,
      hasGiveVoucherToLienViet: false,
      hasGiveVoucherToMobilePortal: false,
      // requiredCreationApproval: false,
      // allowSMSDelivery: false,
      deliveryNotificationEmail: null,
      managerNotificationEmail: null
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  async created () {
    this.getEntityConfiguration()
  },
  methods: {
    ruleValidEmailAllowNull (value) {
      return validationRules.ruleValidEmailFormat(value, null, true)
    },
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.updateEntityConfiguration()
      }
    },
    updateEntityConfiguration: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        // numberOfUsersInEntity: this.entity_cfg.numberOfUsersInEntity,
        // numberOfVoucherCreateEach: this.entity_cfg.numberOfVoucherCreateEach,
        // numberOfVoucherPerMonth: this.entity_cfg.numberOfVoucherPerMonth,
        // numberOfVoucherCreated: this.entity_cfg.numberOfVoucherCreated,
        // numberOfSmsSent: this.entity_cfg.numberOfSmsSent,
        // numberOfAgentCreated: this.entity_cfg.numberOfAgentCreated,
        // numberOfSiteCreated: this.entity_cfg.numberOfSiteCreated,
        hasManageWebPortal: this.entity_cfg.hasManageWebPortal,
        hasGiveVoucherToHaravan: this.entity_cfg.hasGiveVoucherToHaravan,
        hasGiveVoucherToLienViet: this.entity_cfg.hasGiveVoucherToLienViet,
        hasGiveVoucherToMobilePortal: this.entity_cfg.hasGiveVoucherToMobilePortal,
        // requiredCreationApproval: this.entity_cfg.requiredCreationApproval,
        // allowSMSDelivery: this.entity_cfg.allowSMSDelivery,
        deliveryNotificationEmail: this.entity_cfg.deliveryNotificationEmail,
        managerNotificationEmail: this.entity_cfg.managerNotificationEmail
      }
      let entityId = sessionStorage.getItem('entityId')
      this.UPDATE_ENTITY_CONFIGURATION({ id: entityId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    getEntityConfiguration: function () {
      let entityId = sessionStorage.getItem('entityId')
      this.GET_ENTITY_CONFIGURATION({ id: entityId }).then(
        function (res) {
          let data = res.data
          // this.entity_cfg.numberOfVoucherCreateEach = data.maximum_voucher_create_each_time
          // this.entity_cfg.numberOfVoucherPerMonth = data.maximum_voucher_per_month
          // this.entity_cfg.numberOfVoucherCreated = data.maximum_voucher_created
          // this.entity_cfg.numberOfSmsSent = data.maximum_sms_sent
          // this.entity_cfg.numberOfAgentCreated = data.maximum_agent_created
          // this.entity_cfg.numberOfSiteCreated = data.maximum_site_created
          // this.entity_cfg.numberOfUsersInEntity = data.maximum_users_in_entity
          this.entity_cfg.hasManageWebPortal = data.enabled_product_portal
          this.entity_cfg.hasGiveVoucherToHaravan = data.enabled_haravan
          this.entity_cfg.hasGiveVoucherToLienViet = data.enabled_lienviet
          this.entity_cfg.hasGiveVoucherToMobilePortal = data.enabled_mobile_portal
          // this.entity_cfg.storeChannelsAllowChangeEntity = data.store_channels_allow_change_entity
          // this.entity_cfg.requiredCreationApproval = data.required_creation_approval
          // this.entity_cfg.allowSMSDelivery = data.allow_sms_delivery
          this.entity_cfg.deliveryNotificationEmail = data.delivery_email_notification
          this.entity_cfg.managerNotificationEmail = data.manager_email_notification
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'UPDATE_ENTITY_CONFIGURATION',
      'GET_ENTITY_CONFIGURATION'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
