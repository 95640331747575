<template>
  <v-layout
    wrap>
    <v-flex md12>
      <material-card
        :title="$t('sideBar.generalConfiguration')"
        color="green"
        flat
        full-width
      >
        <div>
          <v-container grid-list-xl>
            <v-form>
              <v-layout
                wrap
                layout
              >
                <v-flex
                  xs6
                  md6
                  sm12
                >
                  <v-select
                    v-model="maximumPageSizeValue"
                    :items="maximumPageSizeList"
                    :label="$t('users.maximumPageSize')"
                    item-text="text"
                    item-value="value"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- {{ $t(data.item.text) }} -->
                      {{ $t(data.item) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- {{ $t(data.item.text) }} -->
                      {{ $t(data.item) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-form>
            <v-spacer/>
            <v-btn
              color="success darken-1"
              small
              float-right
              @click="onConfirm"
            >{{ $t('common.save') }}</v-btn>
          </v-container>
        </div>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
export default {
  components: {
  },
  data: () => ({
    storeChannelsList: [],
    maximumPageSizeValue: 5,
    maximumPageSizeList: [5, 10, 15, 20, 25, 50, 100]
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  async created () {
    this.getUserGeneralConfiguration()
  },
  methods: {
    /**
     * Confirm
     */
    onConfirm: function () {
      let data = {
        maximumPageSizeValue: this.maximumPageSizeValue
      }
      this.POST_USER_GENERAL_CONFIGURATION(data)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
            this.getUserGeneralConfiguration()
          }.bind(this)
        )
        .catch(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
            this.isLoading = false
          }.bind(this)
        )
    },
    getUserGeneralConfiguration: function () {
      this.GET_USER_GENERAL_CONFIGURATION()
        .then(
          function (res) {
            let data = res.data
            this.maximumPageSizeValue = data.per_page
          }.bind(this)
        )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_USER_GENERAL_CONFIGURATION',
      'POST_USER_GENERAL_CONFIGURATION'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
