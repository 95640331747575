<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <!-- Config company information -->
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('generalConfiguration.companyInfo')"
          color="green"
          flat
          full-width
        >
          <companyConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="!isAdmin() && isEntityType(EntityType.SUPPLIER) && isManager()">
      <v-flex md12>
        <generalEntityConfiguration />
      </v-flex>
    </v-layout>
    <v-layout v-if="!forConasi && isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('sideBar.generalConfiguration')"
          color="green"
          flat
          full-width
        >
          <generalConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="isAdmin()"
      wrap>
      <v-flex md12>
        <material-card
          :title="'SMTP'"
          color="green"
          flat
          full-width
        >
          <v-expansion-panel popout>
            <v-expansion-panel-content
              v-for="(item, i) in smtpItems"
              :key="i"
            >
              <template v-slot:header>
                <div v-if="item.key == 'smtpServer'">
                  {{ $t('generalConfiguration.serverSMTP') }}
                </div>
                <div v-else-if="item.key == 'smtpInfo'">
                  {{ $t('generalConfiguration.smtpConfigurationInfo') }}
                </div>
                <div v-else>
                  {{ $t('generalConfiguration.emailConfiguration') }}
                </div>
              </template>
              <template>
                <div v-if="item.key == 'smtpServer'">
                  <v-container
                    v-if="isSuperAdmin()"
                    grid-list-xl>
                    <v-form>
                      <v-layout
                        wrap
                        layout
                      >
                        <v-flex
                          xs12
                          sm6
                          md6>
                          <v-autocomplete
                            :rules="[ruleRequiredValue]"
                            v-model="projectDefaultId"
                            :items="allProjects"
                            :clearable="true"
                            :label="$t('customization.projectDefault')"
                            type="text"
                            class="required"
                            @change="projectChange"
                          />
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <v-spacer/>
                  </v-container>
                </div>
                <div v-else-if="item.key == 'smtpInfo'">
                  <v-container grid-list-xl>
                    <v-form>
                      <v-layout
                        wrap
                        layout
                      >
                        <v-flex
                          v-if="isSuperAdmin()"
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_project.project_name"
                            :label="$t('generalConfiguration.name')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_project.sender_alias"
                            :label="$t('generalConfiguration.sender_alias')"
                          />
                        </v-flex>
                        <v-flex
                          v-if="isSuperAdmin()"
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_project.description"
                            :label="$t('generalConfiguration.description')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_project.page_size"
                            :label="$t('generalConfiguration.pageSize')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-textarea
                            v-model="smtp_project.sub_content"
                            :label="$t('generalConfiguration.subContent')"
                          />
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <v-spacer/>
                    <v-btn
                      color="success darken-1"
                      small
                      float-right
                      @click="saveSMTPProject"
                    >{{ $t('common.save') }}</v-btn>
                  </v-container>
                </div>
                <div v-else>
                  <v-container grid-list-xl>
                    <v-form>
                      <v-layout
                        wrap
                        layout
                      >
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_email.smtp_domain"
                            :label="$t('generalConfiguration.serverSMTP')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_email.smtp_port"
                            :label="$t('generalConfiguration.smtp_port')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="smtp_email.smtp_sender"
                            :label="$t('generalConfiguration.emailAccount')"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm12
                          md12
                        >
                          <v-checkbox
                            :label="$t('generalConfiguration.useTLS')"
                            v-model="smtp_email.smtp_use_tls"/>
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <v-spacer/>
                    <v-btn
                      color="success darken-1"
                      small
                      float-right
                      @click="saveSMTPEmailServer"
                    >{{ $t('common.save') }}</v-btn>
                    <v-btn
                      color="warning darken-1"
                      small
                      @click="onShowModalChangePasswordSystemConfig(ServiceType.EMAIL_SMTP)"
                    >{{ $t('common.changePassword') }}</v-btn>
                    <v-btn
                      color="info darken-1"
                      small
                      @click="sendTestEmail"
                    >{{ $t('generalConfiguration.sendTestEmail') }}</v-btn>
                  </v-container>
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </material-card>
      </v-flex>
    </v-layout>
    <!-- Config sms -->
    <v-layout v-if="isAdmin() || (!forConasi && isEntityType(EntityType.SUPPLIER) && isManager())">
      <v-flex md12>
        <material-card
          :title="$t('smsConfig.info')"
          color="green"
          flat
          full-width
        >
          <smsServerConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="!isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('generalConfiguration.discount')"
          color="green"
          flat
          full-width
        >
          <discount-config />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="isEntityType(EntityType.SUPPLIER) && isManager()">
      <v-flex md12>
        <material-card
          :title="$t('customization.configOutgoingEmail')"
          color="green"
          flat
          full-width
        >
          <supplier-smtp-config />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <configurationDefaultEntityForMobileApp />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex md12>
        <userGeneralConfiguration />
      </v-flex>
    </v-layout>
    <!-- <v-layout v-if="isAdmin()">
      <v-flex md12>
        <configurationDefaultEntityForLienViet />
      </v-flex>
    </v-layout> -->
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <changePasswordModal
      ref="changePasswordModal"
      @updatePasswordSMTPEmail="onUpdatePasswordSMTPEmail"/>
    <sendTestEmail
      ref="sendTestEmail"/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import DiscountConfig from './DiscountConfig'
import ToastType from '@/enum/toastType'
import EntityType from 'enum/entityType'
import SupplierSmtpConfig from './SupplierSmtpConfig'
import GeneralConfig from './GeneralConfig'
import CompanyConfig from './CompanyConfig'
import SmsServerConfig from './SmsConfig'
import ServiceType from 'enum/serviceType'
import EntityRoleType from 'enum/entityRoleType'
import ChangePasswordModal from './ChangePasswordModal'
import SendTestEmail from './SendTestEmail'
import ConfigurationDefaultEntityForMobileApp from './ConfigurationDefaultEntityForMobileApp'
import UserGeneralConfiguration from './UserGeneralConfiguration'
import GeneralEntityConfiguration from './GeneralEntityConfiguration'
export default {
  components: {
    DiscountConfig,
    SupplierSmtpConfig,
    GeneralConfig,
    CompanyConfig,
    SmsServerConfig,
    ChangePasswordModal,
    SendTestEmail,
    ConfigurationDefaultEntityForMobileApp,
    UserGeneralConfiguration,
    GeneralEntityConfiguration
  },
  data: () => ({
    valid: false,
    smtp_email: {
      smtp_domain: null,
      smtp_sender: null,
      smtp_port: null,
      smtp_use_tls: true,
      smtp_password: null
    },
    smtp_project: {
      project_name: null,
      sender_alias: null,
      sub_content: null,
      page_size: null,
      description: null
    },
    forConasi: FOR_CONASI_TARGET,
    EntityType: {
      SUPPLIER: EntityType.SUPPLIER
    },
    allProjects: [],
    projectDefaultId: null,
    smtpItems: [
      {
        key: 'smtpServer'
      },
      {
        key: 'smtpInfo'
      },
      {
        key: 'emailConfig'
      }
    ],
    ServiceType: {
      SERVER_SMTP: ServiceType.SERVER_SMTP,
      EMAIL_SMTP: ServiceType.EMAIL_SMTP
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SMTP_PROJECT_DATA',
      'GET_CONFIG_SMTP_EMAIL_SERVER_DATA',
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR',
      'OBJECT_SETTING_DATA'
    ])
  },
  watch: {
    GET_SMTP_PROJECT_DATA: function () {
      let res = this.GET_SMTP_PROJECT_DATA
      this.smtp_project.project_name = res.project_name && res.project_name
      this.smtp_project.sender_alias = res.sender_alias && res.sender_alias
      this.smtp_project.sub_content = res.tail_content && res.tail_content
      this.smtp_project.page_size = res.pagination && res.pagination
      this.smtp_project.description = res.description && res.description
    },
    GET_CONFIG_SMTP_EMAIL_SERVER_DATA: function () {
      let res = this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA
      this.smtp_email.smtp_domain = res.smtp_domain && res.smtp_domain
      this.smtp_email.smtp_sender = res.smtp_sender && res.smtp_sender
      this.smtp_email.smtp_port = res.smtp_port && res.smtp_port
      this.smtp_email.smtp_use_tls = res.smtp_use_tls && res.smtp_use_tls
      this.smtp_email.smtp_password = null
    },
    GET_CURRENT_ACCOUNT () {
      if (!this.isSuperAdmin()) {
        this.smtpItems = this.smtpItems.filter((element) => {
          return element.key !== 'smtpServer'
        })
      } else {
        const found = this.smtpItems.find(element => element.key === 'smtpServer')
        if (!found) {
          this.smtpItems.unshift({
            key: 'smtpServer'
          })
        }
      }
    }
  },
  created () {
    this.getDefaultSmtpProject()
    this.getSMTPProject()
    this.getSMTPEmailServer()
    this.GET_LIST_SMTP_PROJECT().then(
      function (res) {
        let data = res.data
        this.allProjects = []
        for (let i = 0, size = data.length; i < size; i++) {
          let obj = {
            value: data[i].id,
            text: data[i].name == null ? '' : data[i].name
          }
          this.allProjects.push(obj)
        }
      }.bind(this)
    )
    if (!this.isSuperAdmin()) {
      this.smtpItems = this.smtpItems.filter((element) => {
        return element.key !== 'smtpServer'
      })
    }
  },
  methods: {
    /**
     * Update password smtp email
     */
    onUpdatePasswordSMTPEmail: function (password) {
      this.smtp_email.smtp_password = password
      let data = {
        smtp_password: this.smtp_email.smtp_password,
        entity_id: null
      }
      this.PUSH_SMTP_EMAIL_PASSWORD(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Show modal config password for system service
     */
    onShowModalChangePasswordSystemConfig: function (serviceType) {
      this.$refs.changePasswordModal.onShowModal(serviceType)
    },
    getDefaultSmtpProject: function () {
      let objectFilter = {
        params: {
          fromSmtpProject: true,
          key: 'project_default',
          objectType: 'Project'
        }
      }
      this.GET_OBJECT_SETTING(objectFilter).then(
        function (res) {
          let data = res.data.data
          let objectSettingData = data[0]
          if (objectSettingData) {
            this.projectDefaultId = Number(objectSettingData.value)
          }
        }.bind(this)
      )
    },
    /**
     * Project change
     */
    projectChange: function () {
      let filter = {
        key: 'project_default',
        objectType: 'Project',
        value: this.projectDefaultId,
        fromSmtpProject: true
      }
      this.CREATE_OBJECT_SETTING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getSMTPProject()
          this.getSMTPEmailServer()
        }.bind(this)
      )
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Is entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isManager: function () {
      return functionUtils.roleInEntity(EntityRoleType.MANAGER)
    },
    /**
     * Save SMTP Server
     */
    saveSMTPEmailServer: function () {
      let data = {
        smtp_domain: this.smtp_email.smtp_domain,
        smtp_sender: this.smtp_email.smtp_sender,
        smtp_use_tls: this.smtp_email.smtp_use_tls,
        smtp_port: this.smtp_email.smtp_port,
        entity_id: null
      }
      this.PUSH_CONFIG_SMTP_EMAIL_SERVER(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Save SMTP Project
     */
    saveSMTPProject: function () {
      let data = {
        project_name: this.smtp_project.project_name,
        sender_alias: this.smtp_project.sender_alias,
        page_size: this.smtp_project.page_size,
        sub_content: this.smtp_project.sub_content,
        description: this.smtp_project.description,
        entity_id: null
      }
      this.PUSH_SMTP_PROJECT(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    sendTestEmail: function () {
      this.$refs.sendTestEmail.onShowModal()
    },
    /**
     * Get SMTP Email server
     */
    getSMTPEmailServer: function () {
      this.GET_CONFIG_SMTP_EMAIL_SERVER()
    },
    /**
     * Get SMTP project
     */
    getSMTPProject: function () {
      this.is_loading = true
      this.GET_SMTP_PROJECT()
    },
    ...mapActions([
      'GET_SMTP_PROJECT',
      'PUSH_SMTP_PROJECT',
      'GET_CONFIG_SMTP_EMAIL_SERVER',
      'PUSH_CONFIG_SMTP_EMAIL_SERVER',
      'PUSH_SMTP_EMAIL_PASSWORD',
      'ON_SHOW_TOAST',
      'GET_LIST_SMTP_PROJECT',
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
