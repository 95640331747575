<template>
  <v-container
    grid-list-xl
  >
    <v-form
      v-if="isAdmin()"
      ref="form"
      lazy-validation
    >
      <v-layout
        layout
        wrap>
        <v-flex
          xs12
          sm6
          md6
        >
          <v-text-field
            :rules="[ ruleValidEmail ]"
            v-model="notification_email"
            :label="$t('Activation Notification Email')"
          />
        </v-flex>
        <v-flex
          xs12
          sm12
          md12
        >
          <v-switch
            v-model="acceptAutomatic"
            :label="$t('Accept Suplier Registration Automatically')"
            color="success"/>
        </v-flex>
      </v-layout>
      <v-btn
        v-if="isAdmin()"
        style="margin-right: 0;"
        class="pull-right"
        color="success"
        @click="saveGeneralConfig($event)"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-ok" />
        </span>
        <span style="text-transform: none;">{{
          $t("common.save")
        }}</span>
      </v-btn>
    </v-form>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
export default {
  components: {},
  data: () => ({
    notification_email: null,
    acceptAutomatic: false
  }),
  computed: {
    ...mapGetters([
      'GET_GENERAL_CONFIG_HANDLER',
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_GENERAL_CONFIG_HANDLER: function () {
      this.notification_email = this.GET_GENERAL_CONFIG_HANDLER.registration_notify_email ? this.GET_GENERAL_CONFIG_HANDLER.registration_notify_email : ''
      this.acceptAutomatic = this.GET_GENERAL_CONFIG_HANDLER.auto_accept_supplier_registration
    }
  },
  created () {
    this.GET_GENERAL_CONFIG()
  },
  methods: {
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    saveGeneralConfig: function () {
      if (this.$refs.form.validate()) {
        let data = {
          registration_notify_email: this.notification_email,
          auto_accept_supplier_registration: this.acceptAutomatic
        }
        this.UPDATE_GENERAL_CONFIG(data).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
            this.GET_GENERAL_CONFIG()
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        )
      }
    },
    ...mapActions([
      'UPDATE_GENERAL_CONFIG',
      'GET_GENERAL_CONFIG',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
